import { ChevronLast, ChevronFirst } from 'lucide-react';
import { createContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { CreateButton, UserInfo } from '../components/layout';
import logo from '../assets/images/icon.jpg';

export const SidebarContext = createContext();

export default function SideBar({ children }) {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const value = {
    expanded,
    pathname: location.pathname,
  };

  return (
    <SidebarContext.Provider value={value}>
      <aside className={`sticky top-0 h-screen transition-all ${expanded ? 'w-54' : 'w-16'}`}>
        <nav className="h-full flex flex-col border-r border-gray-500 shadow-sm">
          <div className={`p-3 pb-2 flex justify-between ${!expanded && 'flex-col'}`}>
            {!expanded && (
              <button
                onClick={() => setExpanded((curr) => !curr)}
                className="p-1.5 px-2 rounded-lg bg-muted hover:bg-muted-foreground mb-2">
                {expanded ? <ChevronFirst /> : <ChevronLast />}
              </button>
            )}
            <div className="flex items-center gap-2">
              <Link to="/">
                <img src={logo} style={{ height: '38px', borderRadius: 10 }} alt="logo" />
              </Link>

              {expanded && (
                <h2 className="text-xl font-bold transition-all w-30 overflow-hidden">
                  Brogrammers
                </h2>
              )}
            </div>

            {expanded && (
              <button
                onClick={() => setExpanded((curr) => !curr)}
                className="p-1.5 px-2 rounded-lg bg-muted hover:bg-muted-foreground">
                {expanded ? <ChevronFirst /> : <ChevronLast />}
              </button>
            )}
          </div>
          <ul className="flex-1 px-2">{children}</ul>
          <CreateButton expanded={expanded} />
          <UserInfo expanded={expanded} />
        </nav>
      </aside>
    </SidebarContext.Provider>
  );
}
