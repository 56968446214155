'use client';
import { TrendingUp } from 'lucide-react';
import { CartesianGrid, Line, LineChart, XAxis, YAxis, Legend } from 'recharts';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from '../../ui/card';
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '../../ui/chart';

// Generate data points for different time complexities
const generateTimeComplexityData = (n = 10) => {
  const data = [];
  for (let i = 1; i <= n; i++) {
    data.push({
      n: i,
      constant: 1, // O(1)
      linear: i, // O(n)
      quadratic: i * i, // O(n²)
      logarithmic: Math.log2(i), // O(log n)
      exponential: Math.pow(2, i), // O(2^n)
    });
  }
  return data;
};

const chartConfig = {
  constant: {
    label: 'O(1)',
    color: 'hsl(var(--chart-1))',
  },
  linear: {
    label: 'O(n)',
    color: 'hsl(var(--chart-2))',
  },
  logarithmic: {
    label: 'O(log n)',
    color: 'hsl(var(--chart-3))',
  },
  quadratic: {
    label: 'O(n²)',
    color: 'hsl(var(--chart-4))',
  },
  cubic: {
    label: 'O(n³)',
    color: 'hsl(var(--chart-5))',
  },
  exponential: {
    label: 'O(2^n)',
    color: 'hsl(var(--chart-5))',
  },
};

export default function TimeComplexityChart({ complexity = 'linear' }) {
  const chartData = generateTimeComplexityData(10);

  // Function to determine which lines to show based on the selected complexity
  const getVisibleLines = (selectedComplexity) => {
    switch (selectedComplexity) {
      case 'O(1)':
        return ['constant'];
      case 'O(n)':
        return ['linear'];
      case 'O(log n)':
        return ['logarithmic'];
      case 'O(n^2)':
        return ['quadratic'];
      case 'O(n^3)':
        return ['cubic'];
      case 'O(2^n)':
        return ['exponential'];
      default:
        return ['linear'];
    }
  };

  const visibleLines = getVisibleLines(complexity);

  return (
    <Card className="p-0">
      <CardHeader>
        <CardTitle>Complexity Visualization</CardTitle>
        <CardDescription>{complexity} Complexity</CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        <ChartContainer config={chartConfig}>
          <LineChart
            data={chartData}
            margin={{
              top: 5,
              right: 10,
              left: 5,
              bottom: 10,
            }}>
            <CartesianGrid strokeDasharray="3 3" />
            {/* <XAxis dataKey="n" label={{ value: 'Input Size (n)', position: 'bottom' }} /> */}
            {/* <YAxis label={{ value: 'Operations', angle: -90, position: 'left' }} /> */}
            <ChartTooltip content={<ChartTooltipContent />} />
            {visibleLines.includes('constant') && (
              <Line
                type="monotone"
                dataKey="constant"
                stroke="hsl(var(--chart-1))"
                name="O(1)"
                strokeWidth={2}
              />
            )}
            {visibleLines.includes('logarithmic') && (
              <Line
                type="monotone"
                dataKey="logarithmic"
                stroke="hsl(var(--chart-2))"
                name="O(log n)"
                strokeWidth={2}
              />
            )}
            {visibleLines.includes('linear') && (
              <Line
                type="monotone"
                dataKey="linear"
                stroke="hsl(var(--chart-3))"
                name="O(n)"
                strokeWidth={2}
              />
            )}
            {visibleLines.includes('quadratic') && (
              <Line
                type="monotone"
                dataKey="quadratic"
                stroke="hsl(var(--chart-4))"
                name="O(n²)"
                strokeWidth={2}
              />
            )}
            {visibleLines.includes('cubic') && (
              <Line
                type="monotone"
                dataKey="cubic"
                stroke="hsl(var(--chart-5))"
                name="O(n³)"
                strokeWidth={2}
              />
            )}
            {visibleLines.includes('exponential') && (
              <Line
                type="monotone"
                dataKey="exponential"
                stroke="hsl(var(--chart-5))"
                name="O(2^n)"
                strokeWidth={2}
              />
            )}
            <Legend />
          </LineChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="leading-none text-muted-foreground">
          Showing how the number of operations grows with input size
        </div>
      </CardFooter>
    </Card>
  );
}
