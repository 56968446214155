import React from 'react';
import { Progress } from '../../ui/progress';
import { Card } from '../../ui/card';
import { Brain, Target, Trophy } from 'lucide-react';

const Blind75Progress = ({ solved = 0 }) => {
  const total = 76;
  const percentage = Math.round((solved / total) * 100);

  const getStatusColor = (percent) => {
    if (percent < 30) return 'text-red-500';
    if (percent < 70) return 'text-yellow-500';
    return 'text-green-500';
  };

  const getMotivationalText = (percent) => {
    if (percent === 0) return 'Start your coding journey today!';
    if (percent === 100) return "You've mastered the Blind 75!";
    if (percent > 70) return 'Almost there, keep pushing!';
    if (percent > 30) return 'Great progress, keep going!';
    return "You're on your way!";
  };

  return (
    <Card className="w-1/2 mb-8 bg-muted/40 backdrop-blur-lg border border-muted/20 shadow-lg">
      <div className="p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <Brain className="w-6 h-6 text-primary" />
            <div>
              <h2 className="text-xl font-semibold">Blind 75 Progress</h2>
              <p className="text-sm text-muted-foreground">
                {getMotivationalText(percentage)}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-right">
              <p className="text-2xl font-bold text-primary">{solved}</p>
              <p className="text-sm text-muted-foreground">Problems Solved</p>
            </div>
            {percentage === 100 ? (
              <Trophy className="w-8 h-8 text-yellow-500" />
            ) : (
              <Target className="w-8 h-8 text-muted-foreground" />
            )}
          </div>
        </div>

        <div className="space-y-3">
          <div className="flex justify-between text-sm mb-2">
            <span className="text-muted-foreground">Progress</span>
            <span className={`font-medium ${getStatusColor(percentage)}`}>
              {percentage}%
            </span>
          </div>

          <Progress value={percentage} className="h-2 bg-muted" />

          <div className="flex justify-between text-sm text-muted-foreground">
            <span>{solved} completed</span>
            <span>{total - solved} remaining</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Blind75Progress;
