import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useBuddy } from '../../../context/useBuddy';
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card';
import { ScrollArea } from '../../ui/scroll-area';
import { Badge } from '../../ui/badge';

function Problem() {
  const { getProblem, problemDetails } = useBuddy();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('qs_id');

  useEffect(() => {
    if (id) {
      getProblem(id);
    }
  }, [id, getProblem]);

  return (
    <ScrollArea className="h-full w-2/5 pr-3">
      <Card className="bg-muted mt-3">
        <CardHeader className="px-3">
          <div className="flex justify-between items-start">
            <div>
              <CardTitle className="text-xl">{problemDetails?.title}</CardTitle>
              <div className="flex gap-2 mt-2">
                <Badge>{problemDetails?.difficulty}</Badge>
                {problemDetails?.topic_tags?.map((tag) => (
                  <Badge key={tag} variant="constructive">
                    {tag}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
        </CardHeader>
        <CardContent className="space-y-6 px-3">
          <div>
            <h3 className="text-lg font-semibold mb-2">Problem Statement</h3>
            <p>{problemDetails?.statement}</p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">Examples</h3>
            <div className="space-y-4">
              {problemDetails?.examples?.map((example, index) => (
                <div key={index} className="bg-primary/30 p-4 rounded-lg">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="font-medium">Input:</p>
                      <code className="text-sm">{example.input}</code>
                    </div>
                    <div>
                      <p className="font-medium">Output:</p>
                      <code className="text-sm">{example?.output}</code>
                    </div>
                  </div>
                  {example.explanation && (
                    <div className="mt-2">
                      <p className="font-medium">Explanation:</p>
                      <p className="text-sm text-gray-200">{example?.explanation}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">Constraints</h3>
            <ul className="list-disc pl-5 space-y-1">
              {problemDetails?.constraints?.map((constraint, index) => (
                <li key={index}>
                  <code>{constraint}</code>
                </li>
              ))}
            </ul>
          </div>
        </CardContent>
      </Card>
    </ScrollArea>
  );
}

export default Problem;
