import React from 'react';
import { BuddyProvider } from '../../context/useBuddy';
import { Problem, PlayArea } from '../../components/dsa/aibuddy';

function AiBuddy() {
  return (
    <BuddyProvider>
      <div className="h-full px-2">
        <div className="flex flex-row gap-1 h-full">
          <Problem />
          <PlayArea />
        </div>
      </div>
    </BuddyProvider>
  );
}

export default AiBuddy;
