import React, { useEffect, useState } from 'react';
import { blind75 } from '../../../assets/js/blind';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table';
import { Youtube, Bot } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Checkbox } from '../../ui/checkbox';
import { useCourse } from '../../../context/useCourseContext';
import { axios } from '../../../services';
import LCLogo from './LCLogo';
import Progress from './progress';

function Blind75Sheet() {
  const { user } = useCourse();
  const [problemsId, setProblemsId] = useState(null);
  const [problems, setProblems] = useState([]);

  const getData = async () => {
    try {
      const res = await axios.get(`/dsa/blind/${user.id}`);
      setProblemsId(res.data.data._id);
      setProblems(res.data.data.problems);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const patchQs = async (qsId) => {
    try {
      const res = await axios.patch(`/dsa/blind/${problemsId}/${qsId}`);
      setProblems(res.data.data.problems);
    } catch (error) {
      alert('Error updating sheet');
    }
  };

  const toggleProblem = (qsId) => {
    setProblems((prevProblems) => {
      if (prevProblems.includes(qsId)) {
        // If it's already included, remove it
        return prevProblems.filter((id) => id !== qsId);
      } else {
        // Otherwise, add it
        return [...prevProblems, qsId];
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Progress solved={problems.length} />
      {Object.keys(blind75).map((topic, index) => (
        <div key={index} id={`topic-${index + 1}`} className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">• {topic}</h2>
          <Table className="rounded-xl">
            <TableHeader>
              <TableRow className="bg-muted/40">
                <TableHead>Status</TableHead>
                <TableHead className="w-2/4">Question</TableHead>
                <TableHead>AI-buddy</TableHead>
                <TableHead>Youtube</TableHead>
                <TableHead>LeetCode</TableHead>
                <TableHead>Difficulty</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {blind75[topic].map((qsItem, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <div
                      onClick={() => {
                        toggleProblem(qsItem.id);
                        patchQs(qsItem.id);
                      }}>
                      <Checkbox checked={problems.includes(qsItem.id)} />
                    </div>
                  </TableCell>
                  <TableCell>{qsItem.qs}</TableCell>
                  <TableCell>
                    <Link to={`/dsa/buddy?qs_id=${qsItem.id}`}>
                      <Bot color="gray" />
                    </Link>
                  </TableCell>

                  <TableCell>
                    <a href={qsItem.yt} target="_blank" rel="noopener noreferrer">
                      <Youtube color="red" size={24} />
                    </a>
                  </TableCell>
                  <TableCell>
                    <a href={qsItem.qs_link} target="_blank" rel="noopener noreferrer">
                      <LCLogo />
                    </a>
                  </TableCell>

                  <TableCell
                    className={`font-semibold ${
                      qsItem.level === 'easy'
                        ? 'text-green-500'
                        : qsItem.level === 'medium'
                        ? 'text-yellow-500'
                        : 'text-red-500'
                    }`}>
                    {qsItem.level}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}
    </div>
  );
}

export default Blind75Sheet;
