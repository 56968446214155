import React, { useRef, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Card, CardHeader, CardTitle, CardContent } from '../../ui/card';
import { ScrollArea } from '../../ui/scroll-area';

const HighlightedCodeInput = ({ code, setCode }) => {
  const textareaRef = useRef(null);
  const highlighterRef = useRef(null);

  // Sync scroll positions between textarea and syntax highlighter
  useEffect(() => {
    const textarea = textareaRef.current;
    const highlighter = highlighterRef.current;

    const syncScroll = () => {
      if (highlighter) {
        highlighter.scrollTop = textarea.scrollTop;
        highlighter.scrollLeft = textarea.scrollLeft;
      }
    };

    textarea.addEventListener('scroll', syncScroll);
    return () => textarea.removeEventListener('scroll', syncScroll);
  }, []);

  // Handle tab key and maintain cursor position
  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;

      // Insert two spaces for tab
      const newCode = code.substring(0, start) + '  ' + code.substring(end);
      setCode(newCode);

      // Set cursor position after the inserted tab
      requestAnimationFrame(() => {
        e.target.selectionStart = e.target.selectionEnd = start + 2;
      });
    }
  };

  // Handle code changes while maintaining cursor position
  const handleChange = (e) => {
    const newCode = e.target.value;
    const cursorPosition = e.target.selectionStart;

    setCode(newCode);

    // Maintain cursor position after state update
    requestAnimationFrame(() => {
      e.target.selectionStart = e.target.selectionEnd = cursorPosition;
    });
  };

  return (
    <Card className="mt-3">
      <CardHeader>
        <CardTitle>Your Solution</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="relative h-[400px] font-mono text-sm rounded-md border">
          {/* Syntax highlighted code */}
          <div
            ref={highlighterRef}
            aria-hidden="true"
            className="absolute inset-0 overflow-auto p-3">
            <SyntaxHighlighter
              language="javascript"
              style={vscDarkPlus}
              customStyle={{
                margin: 0,
                background: 'transparent',
                fontSize: '14px',
                fontFamily: 'inherit',
                minHeight: '100%',
              }}
              codeTagProps={{
                style: {
                  fontSize: '14px',
                  fontFamily: 'inherit',
                },
              }}>
              {code || ' '}
            </SyntaxHighlighter>
          </div>

          {/* Actual textarea */}
          <textarea
            ref={textareaRef}
            value={code}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            spellCheck="false"
            className="absolute inset-0 w-full h-full resize-none overflow-auto bg-transparent p-3 text-transparent caret-white focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
            placeholder="Write your solution here..."
            style={{
              caretColor: 'white',
              fontFamily: 'inherit',
              fontSize: '14px',
              lineHeight: 'inherit',
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default HighlightedCodeInput;
