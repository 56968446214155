import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from '../services/axios';

const BuddyContext = createContext();

export function BuddyProvider({ children }) {
  const [code, setCode] = useState('');
  const [selectedTab, setSelectedTab] = useState('problem');
  const [problemDetails, setproblemDetails] = useState(null);
  const [explanation, setExplanation] = useState(null);
  const [analysis, setAnalysis] = useState(null);

  const getProblem = async (id) => {
    try {
      const qs = await axios.get(`/aibuddy/${id}`);
      setproblemDetails(qs.data.data);
    } catch (error) {
      alert('error fetching problem');
    }
  };

  // Previous helper functions remain the same
  const generateExplanation = async () => {
    try {
      const getRes = await axios.post('/aibuddy/explain', { data: problemDetails.statement });
      const res = getRes.data.data;
      setExplanation(res);
      return res;
    } catch (error) {
      return "This code appears to be implementing a binary search algorithm. Let's break it down...";
    }
  };

  const generateHint = () => {
    return 'Consider what happens when the array is empty. Have you handled all edge cases?';
  };

  const analyzeCode = async () => {
    try {
      const res = await axios.post('/aibuddy/analyze', { data: code });
      setAnalysis(res.data.data);
    } catch (error) {
      console.log('Error fetching complexity', error);
    }
  };

  const generateFeedback = () => {
    return {
      overall: 'Good implementation with room for improvement',
      strengths: ['Correct algorithm implementation', 'Good basic structure'],
      improvements: ['Add comments', 'Consider edge cases'],
    };
  };

  return (
    <BuddyContext.Provider
      value={{
        getProblem,
        explanation,
        code,
        analysis,
        setCode,
        selectedTab,
        setSelectedTab,
        analyzeCode,
        generateFeedback,
        generateHint,
        generateExplanation,
        problemDetails,
      }}>
      {children}
    </BuddyContext.Provider>
  );
}

export const useBuddy = () => {
  return useContext(BuddyContext);
};
